import _process from "process";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
var process = _process;
!function (e, t) {
  t(exports);
}(exports, function (e) {
  "use strict";

  function t(e, t) {
    if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
  }

  function n(e, t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
    }
  }

  function r(e, t, r) {
    return t && n(e.prototype, t), r && n(e, r), e;
  }

  function i(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }

  function a(e, t) {
    if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
    e.prototype = Object.create(t && t.prototype, {
      constructor: {
        value: e,
        writable: !0,
        configurable: !0
      }
    });
    var n = ["prototype", "__proto__", "caller", "arguments", "length", "name"];
    Object.getOwnPropertyNames(t).forEach(function (r) {
      -1 === n.indexOf(r) && e[r] !== t[r] && (e[r] = t[r]);
    }), t && u(e, t);
  }

  function s(e) {
    return (s = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }

  function u(e, t) {
    return (u = Object.setPrototypeOf || function (e, t) {
      return e.__proto__ = t, e;
    })(e, t);
  }

  function o() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;

    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }

  function f(e, t, n) {
    return (f = o() ? Reflect.construct : function (e, t, n) {
      var r = [null];
      r.push.apply(r, t);
      var i = new (Function.bind.apply(e, r))();
      return n && u(i, n.prototype), i;
    }).apply(null, arguments);
  }

  function c(e) {
    var t = "function" == typeof Map ? new Map() : void 0;
    return (c = function (e) {
      if (null === e || (n = e, -1 === Function.toString.call(n).indexOf("[native code]"))) return e;
      var n;
      if ("function" != typeof e) throw new TypeError("Super expression must either be null or a function");

      if (void 0 !== t) {
        if (t.has(e)) return t.get(e);
        t.set(e, r);
      }

      function r() {
        return f(e, arguments, s(this || _global).constructor);
      }

      return r.prototype = Object.create(e.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), u(r, e);
    })(e);
  }

  function h(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }

  function l(e, t) {
    return !t || "object" != typeof t && "function" != typeof t ? h(e) : t;
  }

  function d(e) {
    var t = o();
    return function () {
      var n,
          r = s(e);

      if (t) {
        var i = s(this || _global).constructor;
        n = Reflect.construct(r, arguments, i);
      } else n = r.apply(this || _global, arguments);

      return l(this || _global, n);
    };
  }

  function v(e, t, n) {
    return (v = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function (e, t, n) {
      var r = function (e, t) {
        for (; !Object.prototype.hasOwnProperty.call(e, t) && null !== (e = s(e)););

        return e;
      }(e, t);

      if (r) {
        var i = Object.getOwnPropertyDescriptor(r, t);
        return i.get ? i.get.call(n) : i.value;
      }
    })(e, t, n || e);
  }

  var p = Object.values || function (e) {
    var t = [];

    for (var n in e) t.push(e[n]);

    return t;
  },
      y = Object.entries || function (e) {
    var t = [];

    for (var n in e) t.push([n, e[n]]);

    return t;
  },
      g = Object.assign || function (e) {
    for (var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) n[r - 1] = arguments[r];

    return n.forEach(function (t) {
      for (var n in t) e[n] = t[n];
    }), e;
  },
      k = Object.fromEntries || function (e) {
    var t = {};
    return m(e).forEach(function (e) {
      var n = e[0],
          r = e[1];
      t[n] = r;
    }), t;
  },
      m = Array.from || function (e) {
    if (e instanceof P) {
      var t = [];
      return e.forEach(function (e, n) {
        return t.push([n, e]);
      }), t;
    }

    return Array.prototype.slice.call(e);
  };

  function b(e) {
    return -1 !== this.indexOf(e);
  }

  Array.prototype.includes || (Array.prototype.includes = b), String.prototype.includes || (String.prototype.includes = b), String.prototype.startsWith || (String.prototype.startsWith = function (e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
    return this.substring(t, t + e.length) === e;
  }), String.prototype.endsWith || (String.prototype.endsWith = function (e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).length;
    return this.substring(t - e.length, t) === e;
  });

  var A = "undefined" != typeof self ? self : _global,
      w = A.fetch || function (e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    return new Promise(function (n, r) {
      var i = new XMLHttpRequest();
      if (i.open("get", e, !0), i.responseType = "arraybuffer", i.onerror = r, t.headers) for (var a in t.headers) i.setRequestHeader(a, t.headers[a]);
      i.onload = function () {
        n({
          ok: i.status >= 200 && i.status < 300,
          status: i.status,
          arrayBuffer: function () {
            return Promise.resolve(i.response);
          }
        });
      }, i.send(null);
    });
  },
      O = function (e) {
    var t = [];
    if (Object.defineProperties(t, {
      size: {
        get: function () {
          return (this || _global).length;
        }
      },
      has: {
        value: function (e) {
          return -1 !== this.indexOf(e);
        }
      },
      add: {
        value: function (e) {
          this.has(e) || this.push(e);
        }
      },
      delete: {
        value: function (e) {
          if (this.has(e)) {
            var t = this.indexOf(e);
            this.splice(t, 1);
          }
        }
      }
    }), Array.isArray(e)) for (var n = 0; n < e.length; n++) t.add(e[n]);
    return t;
  },
      S = function (e) {
    return new P(e);
  },
      P = void 0 !== A.Map && void 0 !== A.Map.prototype.keys ? A.Map : function () {
    function e(n) {
      if (t(this || _global, e), this.clear(), n) for (var r = 0; r < n.length; r++) this.set(n[r][0], n[r][1]);
    }

    return r(e, [{
      key: "clear",
      value: function () {
        (this || _global)._map = {}, (this || _global)._keys = [];
      }
    }, {
      key: "size",
      get: function () {
        return (this || _global)._keys.length;
      }
    }, {
      key: "get",
      value: function (e) {
        return (this || _global)._map["map_" + e];
      }
    }, {
      key: "set",
      value: function (e, t) {
        return (this || _global)._map["map_" + e] = t, (this || _global)._keys.indexOf(e) < 0 && (this || _global)._keys.push(e), this || _global;
      }
    }, {
      key: "has",
      value: function (e) {
        return (this || _global)._keys.indexOf(e) >= 0;
      }
    }, {
      key: "delete",
      value: function (e) {
        var t = (this || _global)._keys.indexOf(e);

        return !(t < 0) && (delete (this || _global)._map["map_" + e], (this || _global)._keys.splice(t, 1), !0);
      }
    }, {
      key: "keys",
      value: function () {
        return (this || _global)._keys.slice(0);
      }
    }, {
      key: "values",
      value: function () {
        var e = this || _global;
        return (this || _global)._keys.map(function (t) {
          return e.get(t);
        });
      }
    }, {
      key: "entries",
      value: function () {
        var e = this || _global;
        return (this || _global)._keys.map(function (t) {
          return [t, e.get(t)];
        });
      }
    }, {
      key: "forEach",
      value: function (e, t) {
        for (var n = 0; n < (this || _global)._keys.length; n++) e.call(t, (this || _global)._map["map_" + (this || _global)._keys[n]], (this || _global)._keys[n], this || _global);
      }
    }]), e;
  }(),
      U = "undefined" != typeof self ? self : _global,
      x = "undefined" != typeof navigator,
      C = x && "undefined" == typeof HTMLImageElement,
      B = !("undefined" == typeof _global || "undefined" == typeof process || !process.versions || !process.versions.node),
      j = U.Buffer,
      _ = !!j;

  var V = function (e) {
    return void 0 !== e;
  };

  function I(e) {
    return void 0 === e || (e instanceof P ? 0 === e.size : 0 === p(e).filter(V).length);
  }

  function L(e) {
    var t = new Error(e);
    throw delete t.stack, t;
  }

  function T(e) {
    var t = function (e) {
      var t = 0;
      return e.ifd0.enabled && (t += 1024), e.exif.enabled && (t += 2048), e.makerNote && (t += 2048), e.userComment && (t += 1024), e.gps.enabled && (t += 512), e.interop.enabled && (t += 100), e.ifd1.enabled && (t += 1024), t + 2048;
    }(e);

    return e.jfif.enabled && (t += 50), e.xmp.enabled && (t += 20000), e.iptc.enabled && (t += 14000), e.icc.enabled && (t += 6000), t;
  }

  var z = function (e) {
    return String.fromCharCode.apply(null, e);
  },
      F = "undefined" != typeof TextDecoder ? new TextDecoder("utf-8") : void 0;

  function E(e) {
    return F ? F.decode(e) : _ ? Buffer.from(e).toString("utf8") : decodeURIComponent(escape(z(e)));
  }

  var D = function () {
    function e(n) {
      var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
          i = arguments.length > 2 ? arguments[2] : void 0,
          a = arguments.length > 3 ? arguments[3] : void 0;
      if (t(this || _global, e), "boolean" == typeof a && ((this || _global).le = a), Array.isArray(n) && (n = new Uint8Array(n)), 0 === n) (this || _global).byteOffset = 0, (this || _global).byteLength = 0;else if (n instanceof ArrayBuffer) {
        void 0 === i && (i = n.byteLength - r);
        var s = new DataView(n, r, i);

        this._swapDataView(s);
      } else if (n instanceof Uint8Array || n instanceof DataView || n instanceof e) {
        void 0 === i && (i = n.byteLength - r), (r += n.byteOffset) + i > n.byteOffset + n.byteLength && L("Creating view outside of available memory in ArrayBuffer");
        var u = new DataView(n.buffer, r, i);

        this._swapDataView(u);
      } else if ("number" == typeof n) {
        var o = new DataView(new ArrayBuffer(n));

        this._swapDataView(o);
      } else L("Invalid input argument for BufferView: " + n);
    }

    return r(e, [{
      key: "_swapArrayBuffer",
      value: function (e) {
        this._swapDataView(new DataView(e));
      }
    }, {
      key: "_swapBuffer",
      value: function (e) {
        this._swapDataView(new DataView(e.buffer, e.byteOffset, e.byteLength));
      }
    }, {
      key: "_swapDataView",
      value: function (e) {
        (this || _global).dataView = e, (this || _global).buffer = e.buffer, (this || _global).byteOffset = e.byteOffset, (this || _global).byteLength = e.byteLength;
      }
    }, {
      key: "_lengthToEnd",
      value: function (e) {
        return (this || _global).byteLength - e;
      }
    }, {
      key: "set",
      value: function (t, n) {
        var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : e;
        t instanceof DataView || t instanceof e ? t = new Uint8Array(t.buffer, t.byteOffset, t.byteLength) : t instanceof ArrayBuffer && (t = new Uint8Array(t)), t instanceof Uint8Array || L("BufferView.set(): Invalid data argument.");
        var i = this.toUint8();
        return i.set(t, n), new r(this || _global, n, t.byteLength);
      }
    }, {
      key: "subarray",
      value: function (t, n) {
        return new e(this || _global, t, n = n || this._lengthToEnd(t));
      }
    }, {
      key: "toUint8",
      value: function () {
        return new Uint8Array((this || _global).buffer, (this || _global).byteOffset, (this || _global).byteLength);
      }
    }, {
      key: "getUint8Array",
      value: function (e, t) {
        return new Uint8Array((this || _global).buffer, (this || _global).byteOffset + e, t);
      }
    }, {
      key: "getString",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
            t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).byteLength,
            n = this.getUint8Array(e, t);
        return E(n);
      }
    }, {
      key: "getLatin1String",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
            t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).byteLength,
            n = this.getUint8Array(e, t);
        return z(n);
      }
    }, {
      key: "getUnicodeString",
      value: function () {
        for (var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0, t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).byteLength, n = [], r = 0; r < t && e + r < (this || _global).byteLength; r += 2) n.push(this.getUint16(e + r));

        return z(n);
      }
    }, {
      key: "getInt8",
      value: function (e) {
        return (this || _global).dataView.getInt8(e);
      }
    }, {
      key: "getUint8",
      value: function (e) {
        return (this || _global).dataView.getUint8(e);
      }
    }, {
      key: "getInt16",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getInt16(e, t);
      }
    }, {
      key: "getInt32",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getInt32(e, t);
      }
    }, {
      key: "getUint16",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getUint16(e, t);
      }
    }, {
      key: "getUint32",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getUint32(e, t);
      }
    }, {
      key: "getFloat32",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getFloat32(e, t);
      }
    }, {
      key: "getFloat64",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getFloat64(e, t);
      }
    }, {
      key: "getFloat",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getFloat32(e, t);
      }
    }, {
      key: "getDouble",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : (this || _global).le;
        return (this || _global).dataView.getFloat64(e, t);
      }
    }, {
      key: "getUintBytes",
      value: function (e, t, n) {
        switch (t) {
          case 1:
            return this.getUint8(e, n);

          case 2:
            return this.getUint16(e, n);

          case 4:
            return this.getUint32(e, n);

          case 8:
            return (this || _global).getUint64 && this.getUint64(e, n);
        }
      }
    }, {
      key: "getUint",
      value: function (e, t, n) {
        switch (t) {
          case 8:
            return this.getUint8(e, n);

          case 16:
            return this.getUint16(e, n);

          case 32:
            return this.getUint32(e, n);

          case 64:
            return (this || _global).getUint64 && this.getUint64(e, n);
        }
      }
    }, {
      key: "toString",
      value: function (e) {
        return (this || _global).dataView.toString(e, (this || _global).constructor.name);
      }
    }, {
      key: "ensureChunk",
      value: function () {}
    }], [{
      key: "from",
      value: function (t, n) {
        return t instanceof (this || _global) && t.le === n ? t : new e(t, void 0, void 0, n);
      }
    }]), e;
  }();

  function R(e, t) {
    L("".concat(e, " '").concat(t, "' was not loaded, try using full build of exifr."));
  }

  var N = function (e) {
    a(i, e);
    var n = d(i);

    function i(e) {
      var r;
      return t(this || _global, i), (r = n.call(this || _global)).kind = e, r;
    }

    return r(i, [{
      key: "get",
      value: function (e, t) {
        return this.has(e) || R((this || _global).kind, e), t && (e in t || function (e, t) {
          L("Unknown ".concat(e, " '").concat(t, "'."));
        }((this || _global).kind, e), t[e].enabled || R((this || _global).kind, e)), v(s(i.prototype), "get", this || _global).call(this || _global, e);
      }
    }, {
      key: "keyList",
      value: function () {
        return m(this.keys());
      }
    }]), i;
  }(c(P)),
      M = new N("file parser"),
      W = new N("segment parser"),
      K = new N("file reader");

  function H(e) {
    return function () {
      for (var t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];

      try {
        return Promise.resolve(e.apply(this || _global, t));
      } catch (e) {
        return Promise.reject(e);
      }
    };
  }

  function X(e, t, n) {
    return n ? t ? t(e) : e : (e && e.then || (e = Promise.resolve(e)), t ? e.then(t) : e);
  }

  var Y = H(function (e) {
    return new Promise(function (t, n) {
      var r = new FileReader();
      r.onloadend = function () {
        return t(r.result || new ArrayBuffer());
      }, r.onerror = n, r.readAsArrayBuffer(e);
    });
  }),
      G = H(function (e) {
    return w(e).then(function (e) {
      return e.arrayBuffer();
    });
  }),
      J = H(function (e, t) {
    return X(t(e), function (e) {
      return new D(e);
    });
  }),
      q = H(function (e, t, n) {
    var r = new (K.get(n))(e, t);
    return X(r.read(), function () {
      return r;
    });
  }),
      Q = H(function (e, t, n, r) {
    return K.has(n) ? q(e, t, n) : r ? J(e, r) : (L("Parser ".concat(n, " is not loaded")), X());
  }),
      Z = "Invalid input argument";

  function $(e, t) {
    return (n = e).startsWith("data:") || n.length > 10000 ? q(e, t, "base64") : x ? Q(e, t, "url", G) : B ? q(e, t, "fs") : void L(Z);
    var n;
  }

  var ee = function (e) {
    a(i, e);
    var n = d(i);

    function i() {
      return t(this || _global, i), n.apply(this || _global, arguments);
    }

    return r(i, [{
      key: "tagKeys",
      get: function () {
        return (this || _global).allKeys || ((this || _global).allKeys = m(this.keys())), (this || _global).allKeys;
      }
    }, {
      key: "tagValues",
      get: function () {
        return (this || _global).allValues || ((this || _global).allValues = m(this.values())), (this || _global).allValues;
      }
    }]), i;
  }(c(P));

  function te(e, t, n) {
    var r = new ee(),
        i = n;
    Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

    for (var a = 0; a < i.length; a++) {
      var s = i[a],
          u = s[0],
          o = s[1];
      r.set(u, o);
    }

    if (Array.isArray(t)) {
      var f = t;
      Array.isArray(f) || ("function" == typeof f.entries && (f = f.entries()), f = m(f));

      for (var c = 0; c < f.length; c++) {
        var h = f[c];
        e.set(h, r);
      }
    } else e.set(t, r);

    return r;
  }

  function ne(e, t, n) {
    var r,
        i = e.get(t),
        a = n;
    Array.isArray(a) || ("function" == typeof a.entries && (a = a.entries()), a = m(a));

    for (var s = 0; s < a.length; s++) r = a[s], i.set(r[0], r[1]);
  }

  var re = S(),
      ie = S(),
      ae = S(),
      se = 37500,
      ue = 37510,
      oe = 33723,
      fe = 34675,
      ce = 34665,
      he = 34853,
      le = 40965,
      de = ["chunked", "firstChunkSize", "firstChunkSizeNode", "firstChunkSizeBrowser", "chunkSize", "chunkLimit"],
      ve = ["jfif", "xmp", "icc", "iptc", "ihdr"],
      pe = ["tiff"].concat(ve),
      ye = ["ifd0", "ifd1", "exif", "gps", "interop"],
      ge = [].concat(pe, ye),
      ke = ["makerNote", "userComment"],
      me = ["translateKeys", "translateValues", "reviveValues", "multiSegment"],
      be = [].concat(me, ["sanitize", "mergeOutput", "silentErrors"]),
      Ae = function () {
    function e() {
      t(this || _global, e);
    }

    return r(e, [{
      key: "translate",
      get: function () {
        return (this || _global).translateKeys || (this || _global).translateValues || (this || _global).reviveValues;
      }
    }]), e;
  }(),
      we = function (e) {
    a(s, e);
    var n = d(s);

    function s(e, r, a, u) {
      var o;
      if (t(this || _global, s), i(h(o = n.call(this || _global)), "enabled", !1), i(h(o), "skip", O()), i(h(o), "pick", O()), i(h(o), "deps", O()), i(h(o), "translateKeys", !1), i(h(o), "translateValues", !1), i(h(o), "reviveValues", !1), o.key = e, o.enabled = r, o.parse = o.enabled, o.applyInheritables(u), o.canBeFiltered = ye.includes(e), o.canBeFiltered && (o.dict = re.get(e)), void 0 !== a) if (Array.isArray(a)) o.parse = o.enabled = !0, o.canBeFiltered && a.length > 0 && o.translateTagSet(a, o.pick);else if ("object" == typeof a) {
        if (o.enabled = !0, o.parse = !1 !== a.parse, o.canBeFiltered) {
          var f = a.pick,
              c = a.skip;
          f && f.length > 0 && o.translateTagSet(f, o.pick), c && c.length > 0 && o.translateTagSet(c, o.skip);
        }

        o.applyInheritables(a);
      } else !0 === a || !1 === a ? o.parse = o.enabled = a : L("Invalid options argument: ".concat(a));
      return o;
    }

    return r(s, [{
      key: "needed",
      get: function () {
        return (this || _global).enabled || (this || _global).deps.size > 0;
      }
    }, {
      key: "applyInheritables",
      value: function (e) {
        var t,
            n,
            r = me;
        Array.isArray(r) || ("function" == typeof r.entries && (r = r.entries()), r = m(r));

        for (var i = 0; i < r.length; i++) void 0 !== (n = e[t = r[i]]) && ((this || _global)[t] = n);
      }
    }, {
      key: "translateTagSet",
      value: function (e, t) {
        if ((this || _global).dict) {
          var n,
              r,
              i = (this || _global).dict,
              a = i.tagKeys,
              s = i.tagValues,
              u = e;
          Array.isArray(u) || ("function" == typeof u.entries && (u = u.entries()), u = m(u));

          for (var o = 0; o < u.length; o++) "string" == typeof (n = u[o]) ? (-1 === (r = s.indexOf(n)) && (r = a.indexOf(Number(n))), -1 !== r && t.add(Number(a[r]))) : t.add(n);
        } else {
          var f = e;
          Array.isArray(f) || ("function" == typeof f.entries && (f = f.entries()), f = m(f));

          for (var c = 0; c < f.length; c++) {
            var h = f[c];
            t.add(h);
          }
        }
      }
    }, {
      key: "finalizeFilters",
      value: function () {
        !(this || _global).enabled && (this || _global).deps.size > 0 ? ((this || _global).enabled = !0, Ce((this || _global).pick, (this || _global).deps)) : (this || _global).enabled && (this || _global).pick.size > 0 && Ce((this || _global).pick, (this || _global).deps);
      }
    }]), s;
  }(Ae),
      Oe = {
    jfif: !1,
    tiff: !0,
    xmp: !1,
    icc: !1,
    iptc: !1,
    ifd0: !0,
    ifd1: !1,
    exif: !0,
    gps: !0,
    interop: !1,
    ihdr: void 0,
    makerNote: !1,
    userComment: !1,
    multiSegment: !1,
    skip: [],
    pick: [],
    translateKeys: !0,
    translateValues: !0,
    reviveValues: !0,
    sanitize: !0,
    mergeOutput: !0,
    silentErrors: !0,
    chunked: !0,
    firstChunkSize: void 0,
    firstChunkSizeNode: 512,
    firstChunkSizeBrowser: 65536,
    chunkSize: 65536,
    chunkLimit: 5
  },
      Se = S(),
      Pe = function (e) {
    a(i, e);
    var n = d(i);

    function i(e) {
      var r;
      return t(this || _global, i), r = n.call(this || _global), !0 === e ? r.setupFromTrue() : void 0 === e ? r.setupFromUndefined() : Array.isArray(e) ? r.setupFromArray(e) : "object" == typeof e ? r.setupFromObject(e) : L("Invalid options argument ".concat(e)), void 0 === r.firstChunkSize && (r.firstChunkSize = x ? r.firstChunkSizeBrowser : r.firstChunkSizeNode), r.mergeOutput && (r.ifd1.enabled = !1), r.filterNestedSegmentTags(), r.traverseTiffDependencyTree(), r.checkLoadedPlugins(), r;
    }

    return r(i, [{
      key: "setupFromUndefined",
      value: function () {
        var e,
            t = de;
        Array.isArray(t) || ("function" == typeof t.entries && (t = t.entries()), t = m(t));

        for (var n = 0; n < t.length; n++) (this || _global)[e = t[n]] = Oe[e];

        var r = be;
        Array.isArray(r) || ("function" == typeof r.entries && (r = r.entries()), r = m(r));

        for (var i = 0; i < r.length; i++) (this || _global)[e = r[i]] = Oe[e];

        var a = ke;
        Array.isArray(a) || ("function" == typeof a.entries && (a = a.entries()), a = m(a));

        for (var s = 0; s < a.length; s++) (this || _global)[e = a[s]] = Oe[e];

        var u = ge;
        Array.isArray(u) || ("function" == typeof u.entries && (u = u.entries()), u = m(u));

        for (var o = 0; o < u.length; o++) (this || _global)[e = u[o]] = new we(e, Oe[e], void 0, this || _global);
      }
    }, {
      key: "setupFromTrue",
      value: function () {
        var e,
            t = de;
        Array.isArray(t) || ("function" == typeof t.entries && (t = t.entries()), t = m(t));

        for (var n = 0; n < t.length; n++) (this || _global)[e = t[n]] = Oe[e];

        var r = be;
        Array.isArray(r) || ("function" == typeof r.entries && (r = r.entries()), r = m(r));

        for (var i = 0; i < r.length; i++) (this || _global)[e = r[i]] = Oe[e];

        var a = ke;
        Array.isArray(a) || ("function" == typeof a.entries && (a = a.entries()), a = m(a));

        for (var s = 0; s < a.length; s++) (this || _global)[e = a[s]] = !0;

        var u = ge;
        Array.isArray(u) || ("function" == typeof u.entries && (u = u.entries()), u = m(u));

        for (var o = 0; o < u.length; o++) (this || _global)[e = u[o]] = new we(e, !0, void 0, this || _global);
      }
    }, {
      key: "setupFromArray",
      value: function (e) {
        var t,
            n = de;
        Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

        for (var r = 0; r < n.length; r++) (this || _global)[t = n[r]] = Oe[t];

        var i = be;
        Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

        for (var a = 0; a < i.length; a++) (this || _global)[t = i[a]] = Oe[t];

        var s = ke;
        Array.isArray(s) || ("function" == typeof s.entries && (s = s.entries()), s = m(s));

        for (var u = 0; u < s.length; u++) (this || _global)[t = s[u]] = Oe[t];

        var o = ge;
        Array.isArray(o) || ("function" == typeof o.entries && (o = o.entries()), o = m(o));

        for (var f = 0; f < o.length; f++) (this || _global)[t = o[f]] = new we(t, !1, void 0, this || _global);

        this.setupGlobalFilters(e, void 0, ye);
      }
    }, {
      key: "setupFromObject",
      value: function (e) {
        var t;
        ye.ifd0 = ye.ifd0 || ye.image, ye.ifd1 = ye.ifd1 || ye.thumbnail, g(this || _global, e);
        var n = de;
        Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

        for (var r = 0; r < n.length; r++) (this || _global)[t = n[r]] = xe(e[t], Oe[t]);

        var i = be;
        Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

        for (var a = 0; a < i.length; a++) (this || _global)[t = i[a]] = xe(e[t], Oe[t]);

        var s = ke;
        Array.isArray(s) || ("function" == typeof s.entries && (s = s.entries()), s = m(s));

        for (var u = 0; u < s.length; u++) (this || _global)[t = s[u]] = xe(e[t], Oe[t]);

        var o = pe;
        Array.isArray(o) || ("function" == typeof o.entries && (o = o.entries()), o = m(o));

        for (var f = 0; f < o.length; f++) (this || _global)[t = o[f]] = new we(t, Oe[t], e[t], this || _global);

        var c = ye;
        Array.isArray(c) || ("function" == typeof c.entries && (c = c.entries()), c = m(c));

        for (var h = 0; h < c.length; h++) (this || _global)[t = c[h]] = new we(t, Oe[t], e[t], (this || _global).tiff);

        this.setupGlobalFilters(e.pick, e.skip, ye, ge), !0 === e.tiff ? this.batchEnableWithBool(ye, !0) : !1 === e.tiff ? this.batchEnableWithUserValue(ye, e) : Array.isArray(e.tiff) ? this.setupGlobalFilters(e.tiff, void 0, ye) : "object" == typeof e.tiff && this.setupGlobalFilters(e.tiff.pick, e.tiff.skip, ye);
      }
    }, {
      key: "batchEnableWithBool",
      value: function (e, t) {
        var n = e;
        Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

        for (var r = 0; r < n.length; r++) {
          (this || _global)[n[r]].enabled = t;
        }
      }
    }, {
      key: "batchEnableWithUserValue",
      value: function (e, t) {
        var n = e;
        Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

        for (var r = 0; r < n.length; r++) {
          var i = n[r],
              a = t[i];
          (this || _global)[i].enabled = !1 !== a && void 0 !== a;
        }
      }
    }, {
      key: "setupGlobalFilters",
      value: function (e, t, n) {
        var r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : n;

        if (e && e.length) {
          var i = r;
          Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

          for (var a = 0; a < i.length; a++) {
            var s = i[a];
            (this || _global)[s].enabled = !1;
          }

          var u = Ue(e, n),
              o = u;
          Array.isArray(o) || ("function" == typeof o.entries && (o = o.entries()), o = m(o));

          for (var f = 0; f < o.length; f++) {
            var c = o[f],
                h = c[0],
                l = c[1];
            Ce((this || _global)[h].pick, l), (this || _global)[h].enabled = !0;
          }
        } else if (t && t.length) {
          var d = Ue(t, n),
              v = d;
          Array.isArray(v) || ("function" == typeof v.entries && (v = v.entries()), v = m(v));

          for (var p = 0; p < v.length; p++) {
            var y = v[p],
                g = y[0],
                k = y[1];
            Ce((this || _global)[g].skip, k);
          }
        }
      }
    }, {
      key: "filterNestedSegmentTags",
      value: function () {
        var e = (this || _global).ifd0,
            t = (this || _global).exif,
            n = (this || _global).xmp,
            r = (this || _global).iptc,
            i = (this || _global).icc;
        (this || _global).makerNote ? t.deps.add(se) : t.skip.add(se), (this || _global).userComment ? t.deps.add(ue) : t.skip.add(ue), n.enabled || e.skip.add(700), r.enabled || e.skip.add(oe), i.enabled || e.skip.add(fe);
      }
    }, {
      key: "traverseTiffDependencyTree",
      value: function () {
        var e = this || _global,
            t = (this || _global).ifd0,
            n = (this || _global).exif,
            r = (this || _global).gps;
        (this || _global).interop.needed && (n.deps.add(le), t.deps.add(le)), n.needed && t.deps.add(ce), r.needed && t.deps.add(he), (this || _global).tiff.enabled = ye.some(function (t) {
          return !0 === e[t].enabled;
        }) || (this || _global).makerNote || (this || _global).userComment;
        var i = ye;
        Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

        for (var a = 0; a < i.length; a++) {
          (this || _global)[i[a]].finalizeFilters();
        }
      }
    }, {
      key: "onlyTiff",
      get: function () {
        var e = this || _global;
        return !ve.map(function (t) {
          return e[t].enabled;
        }).some(function (e) {
          return !0 === e;
        }) && (this || _global).tiff.enabled;
      }
    }, {
      key: "checkLoadedPlugins",
      value: function () {
        var e = pe;
        Array.isArray(e) || ("function" == typeof e.entries && (e = e.entries()), e = m(e));

        for (var t = 0; t < e.length; t++) {
          var n = e[t];
          (this || _global)[n].enabled && !W.has(n) && R("segment parser", n);
        }
      }
    }], [{
      key: "useCached",
      value: function (e) {
        var t = Se.get(e);
        return void 0 !== t || (t = new (this || _global)(e), Se.set(e, t)), t;
      }
    }]), i;
  }(Ae);

  function Ue(e, t) {
    var n,
        r,
        i,
        a = [],
        s = t;
    Array.isArray(s) || ("function" == typeof s.entries && (s = s.entries()), s = m(s));

    for (var u = 0; u < s.length; u++) {
      r = s[u], n = [];
      var o = re.get(r);
      Array.isArray(o) || ("function" == typeof o.entries && (o = o.entries()), o = m(o));

      for (var f = 0; f < o.length; f++) i = o[f], (e.includes(i[0]) || e.includes(i[1])) && n.push(i[0]);

      n.length && a.push([r, n]);
    }

    return a;
  }

  function xe(e, t) {
    return void 0 !== e ? e : void 0 !== t ? t : void 0;
  }

  function Ce(e, t) {
    var n = t;
    Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

    for (var r = 0; r < n.length; r++) {
      var i = n[r];
      e.add(i);
    }
  }

  function Be(e, t, n) {
    return n ? t ? t(e) : e : (e && e.then || (e = Promise.resolve(e)), t ? e.then(t) : e);
  }

  function je() {}

  function _e(e, t) {
    if (!t) return e && e.then ? e.then(je) : Promise.resolve();
  }

  function Ve(e, t) {
    var n = e();
    return n && n.then ? n.then(t) : t(n);
  }

  i(Pe, "default", Oe);

  var Ie = function () {
    function e(n) {
      t(this || _global, e), i(this || _global, "parsers", {}), (this || _global).options = Pe.useCached(n);
    }

    return r(e, [{
      key: "setup",
      value: function () {
        if (!(this || _global).fileParser) {
          var e = (this || _global).file,
              t = e.getUint16(0),
              n = M;
          Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

          for (var r = 0; r < n.length; r++) {
            var i = n[r],
                a = i[0],
                s = i[1];
            if (s.canHandle(e, t)) return (this || _global).fileParser = new s((this || _global).options, (this || _global).file, (this || _global).parsers), e[a] = !0;
          }

          L("Unknown file format");
        }
      }
    }, {
      key: "read",
      value: function (e) {
        try {
          var t = this || _global;
          return Be(function (e, t) {
            return "string" == typeof e ? $(e, t) : x && !C && e instanceof HTMLImageElement ? $(e.src, t) : e instanceof Uint8Array || e instanceof ArrayBuffer || e instanceof DataView ? new D(e) : x && e instanceof Blob ? Q(e, t, "blob", Y) : void L(Z);
          }(e, t.options), function (e) {
            t.file = e;
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "parse",
      value: function () {
        try {
          var e = this || _global;
          e.setup();
          var t = {},
              n = [];
          return Ve(function () {
            return e.options.silentErrors ? Be(e.doParse(t, n).catch(function (e) {
              return n.push(e);
            }), function () {
              n.push.apply(n, e.fileParser.errors);
            }) : _e(e.doParse(t, n));
          }, function () {
            return e.file.close && e.file.close(), e.options.silentErrors && n.length > 0 && (t.errors = n), I(r = t) ? void 0 : r;
            var r;
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "doParse",
      value: function (e, t) {
        try {
          var n = this || _global;
          return Be(n.fileParser.parse(), function () {
            var r,
                i = p(n.parsers).map((r = function (t) {
              return Be(t.parse(), function (n) {
                t.assignToOutput(e, n);
              });
            }, function () {
              for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];

              try {
                return Promise.resolve(r.apply(this || _global, e));
              } catch (e) {
                return Promise.reject(e);
              }
            }));

            if (n.options.silentErrors) {
              var a = function (e) {
                return t.push(e);
              };

              i = i.map(function (e) {
                return e.catch(a);
              });
            }

            return _e(Promise.all(i));
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "extractThumbnail",
      value: function () {
        try {
          var e = this || _global;
          e.setup();
          var t,
              n = e.options,
              r = e.file,
              i = W.get("tiff", n);
          return Ve(function () {
            if (!r.tiff) return function (e) {
              var t = e();
              if (t && t.then) return t.then(je);
            }(function () {
              if (r.jpeg) return Be(e.fileParser.getOrFindSegment("tiff"), function (e) {
                t = e;
              });
            });
            t = {
              start: 0,
              type: "tiff"
            };
          }, function () {
            if (void 0 !== t) return Be(e.fileParser.ensureSegmentChunk(t), function (t) {
              return Be((e.parsers.tiff = new i(t, n, r)).extractThumbnail(), function (e) {
                return r.close && r.close(), e;
              });
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }]), e;
  }();

  var Le,
      Te = (Le = function (e, t) {
    var n,
        r,
        i,
        a = new Ie(t);
    return n = a.read(e), r = function () {
      return a.parse();
    }, i ? r ? r(n) : n : (n && n.then || (n = Promise.resolve(n)), r ? n.then(r) : n);
  }, function () {
    for (var e = [], t = 0; t < arguments.length; t++) e[t] = arguments[t];

    try {
      return Promise.resolve(Le.apply(this || _global, e));
    } catch (e) {
      return Promise.reject(e);
    }
  }),
      ze = Object.freeze({
    __proto__: null,
    parse: Te,
    Exifr: Ie,
    fileParsers: M,
    segmentParsers: W,
    fileReaders: K,
    tagKeys: re,
    tagValues: ie,
    tagRevivers: ae,
    createDictionary: te,
    extendDictionary: ne,
    fetchUrlAsArrayBuffer: G,
    readBlobAsArrayBuffer: Y,
    chunkedProps: de,
    otherSegments: ve,
    segments: pe,
    tiffBlocks: ye,
    segmentsAndBlocks: ge,
    tiffExtractables: ke,
    inheritables: me,
    allFormatters: be,
    Options: Pe
  });

  function Fe() {}

  var Ee = function () {
    function e(n, r, a) {
      var s = this || _global;
      t(this || _global, e), i(this || _global, "errors", []), i(this || _global, "ensureSegmentChunk", function (e) {
        return function () {
          for (var t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];

          try {
            return Promise.resolve(e.apply(this || _global, t));
          } catch (e) {
            return Promise.reject(e);
          }
        };
      }(function (e) {
        var t,
            n,
            r,
            i = e.start,
            a = e.size || 65536;
        return t = function () {
          if (s.file.chunked) return function (e) {
            var t = e();
            if (t && t.then) return t.then(Fe);
          }(function () {
            if (!s.file.available(i, a)) return function (e) {
              if (e && e.then) return e.then(Fe);
            }(function (e, t) {
              try {
                var n = e();
              } catch (e) {
                return t(e);
              }

              return n && n.then ? n.then(void 0, t) : n;
            }(function () {
              return t = s.file.readChunk(i, a), n = function (t) {
                e.chunk = t;
              }, r ? n ? n(t) : t : (t && t.then || (t = Promise.resolve(t)), n ? t.then(n) : t);
              var t, n, r;
            }, function (t) {
              L("Couldn't read segment: ".concat(JSON.stringify(e), ". ").concat(t.message));
            }));
            e.chunk = s.file.subarray(i, a);
          });
          s.file.byteLength > i + a ? e.chunk = s.file.subarray(i, a) : void 0 === e.size ? e.chunk = s.file.subarray(i) : L("Segment unreachable: " + JSON.stringify(e));
        }, n = function () {
          return e.chunk;
        }, (r = t()) && r.then ? r.then(n) : n(r);
      })), (this || _global).extendOptions && this.extendOptions(n), (this || _global).options = n, (this || _global).file = r, (this || _global).parsers = a;
    }

    return r(e, [{
      key: "injectSegment",
      value: function (e, t) {
        (this || _global).options[e].enabled && this.createParser(e, t);
      }
    }, {
      key: "createParser",
      value: function (e, t) {
        var n = new (W.get(e))(t, (this || _global).options, (this || _global).file);
        return (this || _global).parsers[e] = n;
      }
    }, {
      key: "createParsers",
      value: function (e) {
        var t = e;
        Array.isArray(t) || ("function" == typeof t.entries && (t = t.entries()), t = m(t));

        for (var n = 0; n < t.length; n++) {
          var r = t[n],
              i = r.type,
              a = r.chunk,
              s = (this || _global).options[i];

          if (s && s.enabled) {
            var u = (this || _global).parsers[i];
            u && u.append || u || this.createParser(i, a);
          }
        }
      }
    }, {
      key: "readSegments",
      value: function (e) {
        try {
          var t = e.map((this || _global).ensureSegmentChunk);
          return function (e, t) {
            if (!t) return e && e.then ? e.then(Fe) : Promise.resolve();
          }(Promise.all(t));
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }]), e;
  }(),
      De = function () {
    function e(n) {
      var r = this || _global,
          a = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
          s = arguments.length > 2 ? arguments[2] : void 0;
      t(this || _global, e), i(this || _global, "errors", []), i(this || _global, "raw", S()), i(this || _global, "handleError", function (e) {
        if (!r.options.silentErrors) throw e;
        r.errors.push(e.message);
      }), (this || _global).chunk = this.normalizeInput(n), (this || _global).file = s, (this || _global).type = (this || _global).constructor.type, (this || _global).globalOptions = (this || _global).options = a, (this || _global).localOptions = a[(this || _global).type], (this || _global).canTranslate = (this || _global).localOptions && (this || _global).localOptions.translate;
    }

    return r(e, [{
      key: "normalizeInput",
      value: function (e) {
        return e instanceof D ? e : new D(e);
      }
    }, {
      key: "translate",
      value: function () {
        (this || _global).canTranslate && ((this || _global).translated = this.translateBlock((this || _global).raw, (this || _global).type));
      }
    }, {
      key: "output",
      get: function () {
        return (this || _global).translated ? (this || _global).translated : (this || _global).raw ? k((this || _global).raw) : void 0;
      }
    }, {
      key: "translateBlock",
      value: function (e, t) {
        var n = ae.get(t),
            r = ie.get(t),
            i = re.get(t),
            a = (this || _global).options[t],
            s = a.reviveValues && !!n,
            u = a.translateValues && !!r,
            o = a.translateKeys && !!i,
            f = {},
            c = e;
        Array.isArray(c) || ("function" == typeof c.entries && (c = c.entries()), c = m(c));

        for (var h = 0; h < c.length; h++) {
          var l = c[h],
              d = l[0],
              v = l[1];
          s && n.has(d) ? v = n.get(d)(v) : u && r.has(d) && (v = this.translateValue(v, r.get(d))), o && i.has(d) && (d = i.get(d) || d), f[d] = v;
        }

        return f;
      }
    }, {
      key: "translateValue",
      value: function (e, t) {
        return t[e] || t.DEFAULT || e;
      }
    }, {
      key: "assignToOutput",
      value: function (e, t) {
        this.assignObjectToOutput(e, (this || _global).constructor.type, t);
      }
    }, {
      key: "assignObjectToOutput",
      value: function (e, t, n) {
        if ((this || _global).globalOptions.mergeOutput) return g(e, n);
        e[t] ? g(e[t], n) : e[t] = n;
      }
    }], [{
      key: "findPosition",
      value: function (e, t) {
        var n = e.getUint16(t + 2) + 2,
            r = "function" == typeof (this || _global).headerLength ? this.headerLength(e, t, n) : (this || _global).headerLength,
            i = t + r,
            a = n - r;
        return {
          offset: t,
          length: n,
          headerLength: r,
          start: i,
          size: a,
          end: i + a
        };
      }
    }, {
      key: "parse",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
            n = new Pe(i({}, (this || _global).type, t)),
            r = new (this || _global)(e, n);
        return r.parse();
      }
    }]), e;
  }();

  function Re(e, t, n) {
    return n ? t ? t(e) : e : (e && e.then || (e = Promise.resolve(e)), t ? e.then(t) : e);
  }

  i(De, "headerLength", 4), i(De, "type", void 0), i(De, "multiSegment", !1), i(De, "canHandle", function () {
    return !1;
  });

  function Ne() {}

  function Me(e, t) {
    if (!t) return e && e.then ? e.then(Ne) : Promise.resolve();
  }

  function We(e) {
    var t = e();
    if (t && t.then) return t.then(Ne);
  }

  function Ke(e, t) {
    var n = e();
    return n && n.then ? n.then(t) : t(n);
  }

  function He(e, t, n) {
    if (!e.s) {
      if (n instanceof Xe) {
        if (!n.s) return void (n.o = He.bind(null, e, t));
        1 & t && (t = n.s), n = n.v;
      }

      if (n && n.then) return void n.then(He.bind(null, e, t), He.bind(null, e, 2));
      e.s = t, e.v = n;
      var r = e.o;
      r && r(e);
    }
  }

  var Xe = function () {
    function e() {}

    return e.prototype.then = function (t, n) {
      var r = new e(),
          i = (this || _global).s;

      if (i) {
        var a = 1 & i ? t : n;

        if (a) {
          try {
            He(r, 1, a((this || _global).v));
          } catch (e) {
            He(r, 2, e);
          }

          return r;
        }

        return this || _global;
      }

      return (this || _global).o = function (e) {
        try {
          var i = e.v;
          1 & e.s ? He(r, 1, t ? t(i) : i) : n ? He(r, 1, n(i)) : He(r, 2, i);
        } catch (e) {
          He(r, 2, e);
        }
      }, r;
    }, e;
  }();

  function Ye(e) {
    return e instanceof Xe && 1 & e.s;
  }

  function Ge(e, t, n) {
    for (var r;;) {
      var i = e();
      if (Ye(i) && (i = i.v), !i) return a;

      if (i.then) {
        r = 0;
        break;
      }

      var a = n();

      if (a && a.then) {
        if (!Ye(a)) {
          r = 1;
          break;
        }

        a = a.s;
      }

      if (t) {
        var s = t();

        if (s && s.then && !Ye(s)) {
          r = 2;
          break;
        }
      }
    }

    var u = new Xe(),
        o = He.bind(null, u, 2);
    return (0 === r ? i.then(c) : 1 === r ? a.then(f) : s.then(h)).then(void 0, o), u;

    function f(r) {
      a = r;

      do {
        if (t && (s = t()) && s.then && !Ye(s)) return void s.then(h).then(void 0, o);
        if (!(i = e()) || Ye(i) && !i.v) return void He(u, 1, a);
        if (i.then) return void i.then(c).then(void 0, o);
        Ye(a = n()) && (a = a.v);
      } while (!a || !a.then);

      a.then(f).then(void 0, o);
    }

    function c(e) {
      e ? (a = n()) && a.then ? a.then(f).then(void 0, o) : f(a) : He(u, 1, a);
    }

    function h() {
      (i = e()) ? i.then ? i.then(c).then(void 0, o) : c(i) : He(u, 1, a);
    }
  }

  function Je(e) {
    return 192 === e || 194 === e || 196 === e || 219 === e || 221 === e || 218 === e || 254 === e;
  }

  function qe(e) {
    return e >= 224 && e <= 239;
  }

  function Qe(e, t, n) {
    var r = W;
    Array.isArray(r) || ("function" == typeof r.entries && (r = r.entries()), r = m(r));

    for (var i = 0; i < r.length; i++) {
      var a = r[i],
          s = a[0];
      if (a[1].canHandle(e, t, n)) return s;
    }
  }

  var Ze = function (e) {
    a(s, e);
    var n = d(s);

    function s() {
      var e;
      t(this || _global, s);

      for (var r = arguments.length, a = new Array(r), u = 0; u < r; u++) a[u] = arguments[u];

      return i(h(e = n.call.apply(n, [this || _global].concat(a))), "appSegments", []), i(h(e), "jpegSegments", []), i(h(e), "unknownSegments", []), e;
    }

    return r(s, [{
      key: "parse",
      value: function () {
        try {
          var e = this || _global;
          return Re(e.findAppSegments(), function () {
            return Re(e.readSegments(e.appSegments), function () {
              e.mergeMultiSegments(), e.createParsers(e.mergedAppSegments || e.appSegments);
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "setupSegmentFinderArgs",
      value: function (e) {
        var t = this || _global;
        !0 === e ? ((this || _global).findAll = !0, (this || _global).wanted = O(W.keyList())) : (e = void 0 === e ? W.keyList().filter(function (e) {
          return t.options[e].enabled;
        }) : e.filter(function (e) {
          return t.options[e].enabled && W.has(e);
        }), (this || _global).findAll = !1, (this || _global).remaining = O(e), (this || _global).wanted = O(e)), (this || _global).unfinishedMultiSegment = !1;
      }
    }, {
      key: "findAppSegments",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0,
            t = arguments.length > 1 ? arguments[1] : void 0;

        try {
          var n = this || _global;
          n.setupSegmentFinderArgs(t);
          var r = n.file,
              i = n.findAll,
              a = n.wanted,
              s = n.remaining;
          return Ke(function () {
            if (!i && n.file.chunked) return i = m(a).some(function (e) {
              var t = W.get(e),
                  r = n.options[e];
              return t.multiSegment && r.multiSegment;
            }), We(function () {
              if (i) return Me(n.file.readWhole());
            });
          }, function () {
            var t = !1;
            if (e = n.findAppSegmentsInRange(e, r.byteLength), !n.options.onlyTiff) return function () {
              if (r.chunked) {
                var i = !1;
                return Ge(function () {
                  return !t && s.size > 0 && !i && (!!r.canReadNextChunk || !!n.unfinishedMultiSegment);
                }, void 0, function () {
                  var a = r.nextChunkOffset,
                      s = n.appSegments.some(function (e) {
                    return !n.file.available(e.offset || e.start, e.length || e.size);
                  });
                  return Ke(function () {
                    return e > a && !s ? Re(r.readNextChunk(e), function (e) {
                      i = !e;
                    }) : Re(r.readNextChunk(a), function (e) {
                      i = !e;
                    });
                  }, function () {
                    void 0 === (e = n.findAppSegmentsInRange(e, r.byteLength)) && (t = !0);
                  });
                });
              }
            }();
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "findAppSegmentsInRange",
      value: function (e, t) {
        t -= 2;

        for (var n, r, i, a, s, u, o = (this || _global).file, f = (this || _global).findAll, c = (this || _global).wanted, h = (this || _global).remaining, l = (this || _global).options; e < t; e++) if (255 === o.getUint8(e)) if (qe(n = o.getUint8(e + 1))) {
          if (r = o.getUint16(e + 2), (i = Qe(o, e, r)) && c.has(i) && (s = (a = W.get(i)).findPosition(o, e), u = l[i], s.type = i, (this || _global).appSegments.push(s), !f && (a.multiSegment && u.multiSegment ? ((this || _global).unfinishedMultiSegment = s.chunkNumber < s.chunkCount, (this || _global).unfinishedMultiSegment || h.delete(i)) : h.delete(i), 0 === h.size))) break;
          l.recordUnknownSegments && ((s = De.findPosition(o, e)).marker = n, (this || _global).unknownSegments.push(s)), e += r + 1;
        } else if (Je(n)) {
          if (r = o.getUint16(e + 2), 218 === n && !1 !== l.stopAfterSos) return;
          l.recordJpegSegments && (this || _global).jpegSegments.push({
            offset: e,
            length: r,
            marker: n
          }), e += r + 1;
        }

        return e;
      }
    }, {
      key: "mergeMultiSegments",
      value: function () {
        var e = this || _global;

        if ((this || _global).appSegments.some(function (e) {
          return e.multiSegment;
        })) {
          var t = function (e, t) {
            for (var n, r, i, a = S(), s = 0; s < e.length; s++) r = (n = e[s])[t], a.has(r) ? i = a.get(r) : a.set(r, i = []), i.push(n);

            return m(a);
          }((this || _global).appSegments, "type");

          (this || _global).mergedAppSegments = t.map(function (t) {
            var n = t[0],
                r = t[1],
                i = W.get(n, e.options);
            return i.handleMultiSegments ? {
              type: n,
              chunk: i.handleMultiSegments(r)
            } : r[0];
          });
        }
      }
    }, {
      key: "getSegment",
      value: function (e) {
        return (this || _global).appSegments.find(function (t) {
          return t.type === e;
        });
      }
    }, {
      key: "getOrFindSegment",
      value: function (e) {
        try {
          var t = this || _global,
              n = t.getSegment(e);
          return Ke(function () {
            if (void 0 === n) return Re(t.findAppSegments(0, [e]), function () {
              n = t.getSegment(e);
            });
          }, function () {
            return n;
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }], [{
      key: "canHandle",
      value: function (e, t) {
        return 65496 === t;
      }
    }]), s;
  }(Ee);

  function $e() {}

  i(Ze, "type", "jpeg"), M.set("jpeg", Ze);

  function et(e, t) {
    if (!t) return e && e.then ? e.then($e) : Promise.resolve();
  }

  function tt(e, t) {
    var n = e();
    return n && n.then ? n.then(t) : t(n);
  }

  var nt = [void 0, 1, 1, 2, 4, 8, 1, 1, 2, 4, 8, 4, 8, 4];

  var rt = function (e) {
    a(i, e);
    var n = d(i);

    function i() {
      return t(this || _global, i), n.apply(this || _global, arguments);
    }

    return r(i, [{
      key: "parse",
      value: function () {
        try {
          var e = this || _global;
          e.parseHeader();
          var t = e.options;
          return tt(function () {
            if (t.ifd0.enabled) return et(e.parseIfd0Block());
          }, function () {
            return tt(function () {
              if (t.exif.enabled) return et(e.safeParse("parseExifBlock"));
            }, function () {
              return tt(function () {
                if (t.gps.enabled) return et(e.safeParse("parseGpsBlock"));
              }, function () {
                return tt(function () {
                  if (t.interop.enabled) return et(e.safeParse("parseInteropBlock"));
                }, function () {
                  return tt(function () {
                    if (t.ifd1.enabled) return et(e.safeParse("parseThumbnailBlock"));
                  }, function () {
                    return e.createOutput();
                  });
                });
              });
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "safeParse",
      value: function (e) {
        var t = this[e]();
        return void 0 !== t.catch && (t = t.catch((this || _global).handleError)), t;
      }
    }, {
      key: "findIfd0Offset",
      value: function () {
        void 0 === (this || _global).ifd0Offset && ((this || _global).ifd0Offset = (this || _global).chunk.getUint32(4));
      }
    }, {
      key: "findIfd1Offset",
      value: function () {
        if (void 0 === (this || _global).ifd1Offset) {
          this.findIfd0Offset();

          var e = (this || _global).chunk.getUint16((this || _global).ifd0Offset),
              t = (this || _global).ifd0Offset + 2 + 12 * e;

          (this || _global).ifd1Offset = (this || _global).chunk.getUint32(t);
        }
      }
    }, {
      key: "parseBlock",
      value: function (e, t) {
        var n = S();
        return (this || _global)[t] = n, this.parseTags(e, t, n), n;
      }
    }, {
      key: "parseIfd0Block",
      value: function () {
        try {
          var e = this || _global;
          if (e.ifd0) return;
          var t = e.file;
          return e.findIfd0Offset(), e.ifd0Offset < 8 && L("Malformed EXIF data"), !t.chunked && e.ifd0Offset > t.byteLength && L("IFD0 offset points to outside of file.\nthis.ifd0Offset: ".concat(e.ifd0Offset, ", file.byteLength: ").concat(t.byteLength)), tt(function () {
            if (t.tiff) return et(t.ensureChunk(e.ifd0Offset, T(e.options)));
          }, function () {
            var t = e.parseBlock(e.ifd0Offset, "ifd0");
            if (0 !== t.size) return e.exifOffset = t.get(ce), e.interopOffset = t.get(le), e.gpsOffset = t.get(he), e.xmp = t.get(700), e.iptc = t.get(oe), e.icc = t.get(fe), e.options.sanitize && (t.delete(ce), t.delete(le), t.delete(he), t.delete(700), t.delete(oe), t.delete(fe)), t;
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "parseExifBlock",
      value: function () {
        try {
          var e = this || _global;
          if (e.exif) return;
          return tt(function () {
            if (!e.ifd0) return et(e.parseIfd0Block());
          }, function () {
            if (void 0 !== e.exifOffset) return tt(function () {
              if (e.file.tiff) return et(e.file.ensureChunk(e.exifOffset, T(e.options)));
            }, function () {
              var t = e.parseBlock(e.exifOffset, "exif");
              return e.interopOffset || (e.interopOffset = t.get(le)), e.makerNote = t.get(se), e.userComment = t.get(ue), e.options.sanitize && (t.delete(le), t.delete(se), t.delete(ue)), e.unpack(t, 41728), e.unpack(t, 41729), t;
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "unpack",
      value: function (e, t) {
        var n = e.get(t);
        n && 1 === n.length && e.set(t, n[0]);
      }
    }, {
      key: "parseGpsBlock",
      value: function () {
        try {
          var e = this || _global;
          if (e.gps) return;
          return tt(function () {
            if (!e.ifd0) return et(e.parseIfd0Block());
          }, function () {
            if (void 0 !== e.gpsOffset) {
              var t = e.parseBlock(e.gpsOffset, "gps");
              return t && t.has(2) && t.has(4) && (t.set("latitude", it.apply(void 0, t.get(2).concat([t.get(1)]))), t.set("longitude", it.apply(void 0, t.get(4).concat([t.get(3)])))), t;
            }
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "parseInteropBlock",
      value: function () {
        try {
          var e = this || _global;
          if (e.interop) return;
          return tt(function () {
            if (!e.ifd0) return et(e.parseIfd0Block());
          }, function () {
            return tt(function () {
              if (void 0 === e.interopOffset && !e.exif) return et(e.parseExifBlock());
            }, function () {
              if (void 0 !== e.interopOffset) return e.parseBlock(e.interopOffset, "interop");
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "parseThumbnailBlock",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];

        try {
          var t = this || _global;
          if (t.ifd1 || t.ifd1Parsed) return;
          if (t.options.mergeOutput && !e) return;
          return t.findIfd1Offset(), t.ifd1Offset > 0 && (t.parseBlock(t.ifd1Offset, "ifd1"), t.ifd1Parsed = !0), t.ifd1;
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "extractThumbnail",
      value: function () {
        try {
          var e = this || _global;
          return e.headerParsed || e.parseHeader(), tt(function () {
            if (!e.ifd1Parsed) return et(e.parseThumbnailBlock(!0));
          }, function () {
            if (void 0 !== e.ifd1) {
              var t = e.ifd1.get(513),
                  n = e.ifd1.get(514);
              return e.chunk.getUint8Array(t, n);
            }
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "image",
      get: function () {
        return (this || _global).ifd0;
      }
    }, {
      key: "thumbnail",
      get: function () {
        return (this || _global).ifd1;
      }
    }, {
      key: "createOutput",
      value: function () {
        var e,
            t,
            n,
            r = {},
            i = ye;
        Array.isArray(i) || ("function" == typeof i.entries && (i = i.entries()), i = m(i));

        for (var a = 0; a < i.length; a++) if (!I(e = (this || _global)[t = i[a]])) if (n = (this || _global).canTranslate ? this.translateBlock(e, t) : k(e), (this || _global).options.mergeOutput) {
          if ("ifd1" === t) continue;
          g(r, n);
        } else r[t] = n;

        return (this || _global).makerNote && (r.makerNote = (this || _global).makerNote), (this || _global).userComment && (r.userComment = (this || _global).userComment), r;
      }
    }, {
      key: "assignToOutput",
      value: function (e, t) {
        if ((this || _global).globalOptions.mergeOutput) g(e, t);else {
          var n = y(t);
          Array.isArray(n) || ("function" == typeof n.entries && (n = n.entries()), n = m(n));

          for (var r = 0; r < n.length; r++) {
            var i = n[r],
                a = i[0],
                s = i[1];
            this.assignObjectToOutput(e, a, s);
          }
        }
      }
    }], [{
      key: "canHandle",
      value: function (e, t) {
        return 225 === e.getUint8(t + 1) && 1165519206 === e.getUint32(t + 4) && 0 === e.getUint16(t + 8);
      }
    }]), i;
  }(function (e) {
    a(i, e);
    var n = d(i);

    function i() {
      return t(this || _global, i), n.apply(this || _global, arguments);
    }

    return r(i, [{
      key: "parseHeader",
      value: function () {
        var e = (this || _global).chunk.getUint16();

        18761 === e ? (this || _global).le = !0 : 19789 === e && ((this || _global).le = !1), (this || _global).chunk.le = (this || _global).le, (this || _global).headerParsed = !0;
      }
    }, {
      key: "parseTags",
      value: function (e, t) {
        var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : S(),
            r = (this || _global).options[t],
            i = r.pick,
            a = r.skip,
            s = (i = O(i)).size > 0,
            u = 0 === a.size,
            o = (this || _global).chunk.getUint16(e);

        e += 2;

        for (var f = 0; f < o; f++) {
          var c = (this || _global).chunk.getUint16(e);

          if (s) {
            if (i.has(c) && (n.set(c, this.parseTag(e, c, t)), i.delete(c), 0 === i.size)) break;
          } else !u && a.has(c) || n.set(c, this.parseTag(e, c, t));

          e += 12;
        }

        return n;
      }
    }, {
      key: "parseTag",
      value: function (e, t, n) {
        var r,
            i = (this || _global).chunk,
            a = i.getUint16(e + 2),
            s = i.getUint32(e + 4),
            u = nt[a];
        if (u * s <= 4 ? e += 8 : e = i.getUint32(e + 8), (a < 1 || a > 13) && L("Invalid TIFF value type. block: ".concat(n.toUpperCase(), ", tag: ").concat(t.toString(16), ", type: ").concat(a, ", offset ").concat(e)), e > i.byteLength && L("Invalid TIFF value offset. block: ".concat(n.toUpperCase(), ", tag: ").concat(t.toString(16), ", type: ").concat(a, ", offset ").concat(e, " is outside of chunk size ").concat(i.byteLength)), 1 === a) return i.getUint8Array(e, s);
        if (2 === a) return "" === (r = function (e) {
          for (; e.endsWith("\0");) e = e.slice(0, -1);

          return e;
        }(r = i.getString(e, s)).trim()) ? void 0 : r;
        if (7 === a) return i.getUint8Array(e, s);
        if (1 === s) return this.parseTagValue(a, e);

        for (var o = new (function (e) {
          switch (e) {
            case 1:
              return Uint8Array;

            case 3:
              return Uint16Array;

            case 4:
              return Uint32Array;

            case 5:
              return Array;

            case 6:
              return Int8Array;

            case 8:
              return Int16Array;

            case 9:
              return Int32Array;

            case 10:
              return Array;

            case 11:
              return Float32Array;

            case 12:
              return Float64Array;

            default:
              return Array;
          }
        }(a))(s), f = u, c = 0; c < s; c++) o[c] = this.parseTagValue(a, e), e += f;

        return o;
      }
    }, {
      key: "parseTagValue",
      value: function (e, t) {
        var n = (this || _global).chunk;

        switch (e) {
          case 1:
            return n.getUint8(t);

          case 3:
            return n.getUint16(t);

          case 4:
            return n.getUint32(t);

          case 5:
            return n.getUint32(t) / n.getUint32(t + 4);

          case 6:
            return n.getInt8(t);

          case 8:
            return n.getInt16(t);

          case 9:
            return n.getInt32(t);

          case 10:
            return n.getInt32(t) / n.getInt32(t + 4);

          case 11:
            return n.getFloat(t);

          case 12:
            return n.getDouble(t);

          case 13:
            return n.getUint32(t);

          default:
            L("Invalid tiff type ".concat(e));
        }
      }
    }]), i;
  }(De));

  function it(e, t, n, r) {
    var i = e + t / 60 + n / 3600;
    return "S" !== r && "W" !== r || (i *= -1), i;
  }

  i(rt, "type", "tiff"), i(rt, "headerLength", 10), W.set("tiff", rt);
  var at = Object.freeze({
    __proto__: null,
    default: ze,
    Exifr: Ie,
    fileParsers: M,
    segmentParsers: W,
    fileReaders: K,
    tagKeys: re,
    tagValues: ie,
    tagRevivers: ae,
    createDictionary: te,
    extendDictionary: ne,
    fetchUrlAsArrayBuffer: G,
    readBlobAsArrayBuffer: Y,
    chunkedProps: de,
    otherSegments: ve,
    segments: pe,
    tiffBlocks: ye,
    segmentsAndBlocks: ge,
    tiffExtractables: ke,
    inheritables: me,
    allFormatters: be,
    Options: Pe,
    parse: Te
  });

  function st(e, t, n) {
    return n ? t ? t(e) : e : (e && e.then || (e = Promise.resolve(e)), t ? e.then(t) : e);
  }

  function ut(e) {
    return function () {
      for (var t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];

      try {
        return Promise.resolve(e.apply(this || _global, t));
      } catch (e) {
        return Promise.reject(e);
      }
    };
  }

  var ot = ut(function (e) {
    var t = new Ie(vt);
    return st(t.read(e), function () {
      return st(t.parse(), function (e) {
        if (e && e.ifd0) return e.ifd0[274];
      });
    });
  }),
      ft = ut(function (e) {
    var t = new Ie(dt);
    return st(t.read(e), function () {
      return st(t.parse(), function (e) {
        if (e && e.gps) {
          var t = e.gps;
          return {
            latitude: t.latitude,
            longitude: t.longitude
          };
        }
      });
    });
  }),
      ct = ut(function (e) {
    return st(this.thumbnail(e), function (e) {
      if (void 0 !== e) {
        var t = new Blob([e]);
        return URL.createObjectURL(t);
      }
    });
  }),
      ht = ut(function (e) {
    var t = new Ie(pt);
    return st(t.read(e), function () {
      return st(t.extractThumbnail(), function (e) {
        return e && _ ? j.from(e) : e;
      });
    });
  }),
      lt = {
    ifd0: !1,
    ifd1: !1,
    exif: !1,
    gps: !1,
    interop: !1,
    sanitize: !1,
    reviveValues: !0,
    translateKeys: !1,
    translateValues: !1,
    mergeOutput: !1
  },
      dt = g({}, lt, {
    firstChunkSize: 40000,
    gps: [1, 2, 3, 4]
  }),
      vt = g({}, lt, {
    firstChunkSize: 40000,
    ifd0: [274]
  }),
      pt = g({}, lt, {
    tiff: !1,
    ifd1: !0,
    mergeOutput: !1
  }),
      yt = Object.freeze({
    1: {
      dimensionSwapped: !1,
      scaleX: 1,
      scaleY: 1,
      deg: 0,
      rad: 0
    },
    2: {
      dimensionSwapped: !1,
      scaleX: -1,
      scaleY: 1,
      deg: 0,
      rad: 0
    },
    3: {
      dimensionSwapped: !1,
      scaleX: 1,
      scaleY: 1,
      deg: 180,
      rad: 180 * Math.PI / 180
    },
    4: {
      dimensionSwapped: !1,
      scaleX: -1,
      scaleY: 1,
      deg: 180,
      rad: 180 * Math.PI / 180
    },
    5: {
      dimensionSwapped: !0,
      scaleX: 1,
      scaleY: -1,
      deg: 90,
      rad: 90 * Math.PI / 180
    },
    6: {
      dimensionSwapped: !0,
      scaleX: 1,
      scaleY: 1,
      deg: 90,
      rad: 90 * Math.PI / 180
    },
    7: {
      dimensionSwapped: !0,
      scaleX: 1,
      scaleY: -1,
      deg: 270,
      rad: 270 * Math.PI / 180
    },
    8: {
      dimensionSwapped: !0,
      scaleX: 1,
      scaleY: 1,
      deg: 270,
      rad: 270 * Math.PI / 180
    }
  });

  if (e.rotateCanvas = !0, e.rotateCss = !0, "object" == typeof navigator) {
    var gt = navigator.userAgent;

    if (gt.includes("iPad") || gt.includes("iPhone")) {
      var kt = gt.match(/OS (\d+)_(\d+)/);

      if (kt) {
        var mt = kt[1],
            bt = kt[2],
            At = Number(mt) + 0.1 * Number(bt);
        e.rotateCanvas = At < 13.4, e.rotateCss = !1;
      }
    } else if (gt.includes("OS X 10")) {
      var wt = gt.match(/OS X 10[_.](\d+)/)[1];
      e.rotateCanvas = e.rotateCss = Number(wt) < 15;
    }

    if (gt.includes("Chrome/")) {
      var Ot = gt.match(/Chrome\/(\d+)/)[1];
      e.rotateCanvas = e.rotateCss = Number(Ot) < 81;
    } else if (gt.includes("Firefox/")) {
      var St = gt.match(/Firefox\/(\d+)/)[1];
      e.rotateCanvas = e.rotateCss = Number(St) < 77;
    }
  }

  function Pt() {}

  var Ut = function (e) {
    a(u, e);
    var n = d(u);

    function u() {
      var e;
      t(this || _global, u);

      for (var r = arguments.length, a = new Array(r), s = 0; s < r; s++) a[s] = arguments[s];

      return i(h(e = n.call.apply(n, [this || _global].concat(a))), "ranges", new xt()), 0 !== e.byteLength && e.ranges.add(0, e.byteLength), e;
    }

    return r(u, [{
      key: "_tryExtend",
      value: function (e, t, n) {
        if (0 === e && 0 === (this || _global).byteLength && n) {
          var r = new DataView(n.buffer || n, n.byteOffset, n.byteLength);

          this._swapDataView(r);
        } else {
          var i = e + t;

          if (i > (this || _global).byteLength) {
            var a = this._extend(i).dataView;

            this._swapDataView(a);
          }
        }
      }
    }, {
      key: "_extend",
      value: function (e) {
        var t;
        t = _ ? j.allocUnsafe(e) : new Uint8Array(e);
        var n = new DataView(t.buffer, t.byteOffset, t.byteLength);
        return t.set(new Uint8Array((this || _global).buffer, (this || _global).byteOffset, (this || _global).byteLength), 0), {
          uintView: t,
          dataView: n
        };
      }
    }, {
      key: "subarray",
      value: function (e, t) {
        var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
        return t = t || this._lengthToEnd(e), n && this._tryExtend(e, t), (this || _global).ranges.add(e, t), v(s(u.prototype), "subarray", this || _global).call(this || _global, e, t);
      }
    }, {
      key: "set",
      value: function (e, t) {
        var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
        n && this._tryExtend(t, e.byteLength, e);
        var r = v(s(u.prototype), "set", this || _global).call(this || _global, e, t);
        return (this || _global).ranges.add(t, r.byteLength), r;
      }
    }, {
      key: "ensureChunk",
      value: function (e, t) {
        try {
          var n = this || _global;
          if (!n.chunked) return;
          if (n.ranges.available(e, t)) return;
          return function (e, t) {
            if (!t) return e && e.then ? e.then(Pt) : Promise.resolve();
          }(n.readChunk(e, t));
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "available",
      value: function (e, t) {
        return (this || _global).ranges.available(e, t);
      }
    }]), u;
  }(D),
      xt = function () {
    function e() {
      t(this || _global, e), i(this || _global, "list", []);
    }

    return r(e, [{
      key: "length",
      get: function () {
        return (this || _global).list.length;
      }
    }, {
      key: "add",
      value: function (e, t) {
        var n = e + t,
            r = (this || _global).list.filter(function (t) {
          return Ct(e, t.offset, n) || Ct(e, t.end, n);
        });

        if (r.length > 0) {
          e = Math.min.apply(Math, [e].concat(r.map(function (e) {
            return e.offset;
          }))), t = (n = Math.max.apply(Math, [n].concat(r.map(function (e) {
            return e.end;
          })))) - e;
          var i = r.shift();
          i.offset = e, i.length = t, i.end = n, (this || _global).list = (this || _global).list.filter(function (e) {
            return !r.includes(e);
          });
        } else (this || _global).list.push({
          offset: e,
          length: t,
          end: n
        });
      }
    }, {
      key: "available",
      value: function (e, t) {
        var n = e + t;
        return (this || _global).list.some(function (t) {
          return t.offset <= e && n <= t.end;
        });
      }
    }]), e;
  }();

  function Ct(e, t, n) {
    return e <= t && t <= n;
  }

  function Bt() {}

  function jt(e, t) {
    if (!t) return e && e.then ? e.then(Bt) : Promise.resolve();
  }

  function _t(e, t, n) {
    return n ? t ? t(e) : e : (e && e.then || (e = Promise.resolve(e)), t ? e.then(t) : e);
  }

  var Vt = function (e) {
    a(i, e);
    var n = d(i);

    function i() {
      return t(this || _global, i), n.apply(this || _global, arguments);
    }

    return r(i, [{
      key: "readWhole",
      value: function () {
        try {
          var e = this || _global;
          return e.chunked = !1, _t(Y(e.input), function (t) {
            e._swapArrayBuffer(t);
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "readChunked",
      value: function () {
        return (this || _global).chunked = !0, (this || _global).size = (this || _global).input.size, v(s(i.prototype), "readChunked", this || _global).call(this || _global);
      }
    }, {
      key: "_readChunk",
      value: function (e, t) {
        try {
          var n = this || _global,
              r = t ? e + t : void 0,
              i = n.input.slice(e, r);
          return _t(Y(i), function (t) {
            return n.set(t, e, !0);
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }]), i;
  }(function (e) {
    a(s, e);
    var n = d(s);

    function s(e, r) {
      var a;
      return t(this || _global, s), i(h(a = n.call(this || _global, 0)), "chunksRead", 0), a.input = e, a.options = r, a;
    }

    return r(s, [{
      key: "readWhole",
      value: function () {
        try {
          var e = this || _global;
          return e.chunked = !1, jt(e.readChunk(e.nextChunkOffset));
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "readChunked",
      value: function () {
        try {
          var e = this || _global;
          return e.chunked = !0, jt(e.readChunk(0, e.options.firstChunkSize));
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "readNextChunk",
      value: function (e) {
        try {
          var t = this || _global;
          if (void 0 === e && (e = t.nextChunkOffset), t.fullyRead) return t.chunksRead++, !1;
          var n = t.options.chunkSize;
          return r = t.readChunk(e, n), i = function (e) {
            return !!e && e.byteLength === n;
          }, a ? i ? i(r) : r : (r && r.then || (r = Promise.resolve(r)), i ? r.then(i) : r);
        } catch (e) {
          return Promise.reject(e);
        }

        var r, i, a;
      }
    }, {
      key: "readChunk",
      value: function (e, t) {
        try {
          var n = this || _global;
          if (n.chunksRead++, 0 === (t = n.safeWrapAddress(e, t))) return;
          return n._readChunk(e, t);
        } catch (e) {
          return Promise.reject(e);
        }
      }
    }, {
      key: "safeWrapAddress",
      value: function (e, t) {
        return void 0 !== (this || _global).size && e + t > (this || _global).size ? Math.max(0, (this || _global).size - e) : t;
      }
    }, {
      key: "nextChunkOffset",
      get: function () {
        if (0 !== (this || _global).ranges.list.length) return (this || _global).ranges.list[0].length;
      }
    }, {
      key: "canReadNextChunk",
      get: function () {
        return (this || _global).chunksRead < (this || _global).options.chunkLimit;
      }
    }, {
      key: "fullyRead",
      get: function () {
        return void 0 !== (this || _global).size && (this || _global).nextChunkOffset === (this || _global).size;
      }
    }, {
      key: "read",
      value: function () {
        return (this || _global).options.chunked ? this.readChunked() : this.readWhole();
      }
    }, {
      key: "close",
      value: function () {}
    }]), s;
  }(Ut));

  K.set("blob", Vt), e.Exifr = Ie, e.Options = Pe, e.allFormatters = be, e.chunkedProps = de, e.createDictionary = te, e.default = at, e.disableAllOptions = lt, e.extendDictionary = ne, e.fetchUrlAsArrayBuffer = G, e.fileParsers = M, e.fileReaders = K, e.gps = ft, e.gpsOnlyOptions = dt, e.inheritables = me, e.orientation = ot, e.orientationOnlyOptions = vt, e.otherSegments = ve, e.parse = Te, e.readBlobAsArrayBuffer = Y, e.rotation = function (t) {
    return st(ot(t), function (t) {
      return g({
        canvas: e.rotateCanvas,
        css: e.rotateCss
      }, yt[t]);
    });
  }, e.rotations = yt, e.segmentParsers = W, e.segments = pe, e.segmentsAndBlocks = ge, e.tagKeys = re, e.tagRevivers = ae, e.tagValues = ie, e.thumbnail = ht, e.thumbnailOnlyOptions = pt, e.thumbnailUrl = ct, e.tiffBlocks = ye, e.tiffExtractables = ke, Object.defineProperty(e, "__esModule", {
    value: !0
  });
});
export default exports;